// eslint-disable-next-line import/prefer-default-export
export enum EStep {
  APPROVE,
  ERROR,
  LAUCH,
  ORDER,
  CANCEL,
  STAKE,
  STAKE_OLD,
  SWAP,
  WETH,
  DONE,
}